import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig, ValidateEmail, normalize, ValidatePhoneNumber } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { verfiyDomain, addNewAgent } from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import Step1 from "../../../components/Agents/Step1";
import Step2 from "../../../components/Agents/Step2";
import Step3 from "../../../components/Agents/Step3";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';
const auth = new HelperClass();

class Addagent extends Component {
  constructor() {
    super();
    this.state = {
      serviceData: [],
      select_service: "",
      errorMessage: "",
      serviceId: "",
      title: "",
      name: "",
      logo: "",
      agent_email: "",
      agent_phone: "",
      agent_company: "",
      agent_password: "",
      c_password: "",
      description: "",
      successMessage: "",
      tableLoader: true,
      domain: "",
      step: 1,
      dnsList: [],
      a_ip: "",
      is_verified: 0,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    /*const servicesResponce = await listDefaultServices(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
      });
    } else {
      //window.location.replace("/error");
    }*/
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  VerifyConnection = async () => {
    const {
      title,
      name,
      domain,
      logo,
      agent_email,
      agent_password,
      c_password,
      agent_company,
    } = this.state;
    console.log("domain_name: ", domain);
    if (domain) {
      const servicesResponce = await verfiyDomain(
        auth.getAccount(),
        auth.getToken(),
        domain
      );
      console.log("DNS RESPONSE: ", servicesResponce.data.dnsList);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        if (servicesResponce.data.dnsList.DNSData) {
          console.log(
            "DNS : ",
            servicesResponce.data.dnsList.DNSData.dnsRecords[0].address
          );
          let local_ip = process.env.REACT_APP_AGENT_PORTAL_DNS;
          let domain_dns =
            servicesResponce.data.dnsList.DNSData.dnsRecords[0].address;

          if (local_ip == domain_dns) {
            this.setState({
              successMessage:
                "Domain is verified. Now you can proceed with adding the agent.",
              is_verified: 1,
              a_ip: domain_dns,
            });
          } else {
            this.setState({
              successMessage: "",
              errorMessage:
                "Your A record is not pointed to our IP address, which is " +
                local_ip +
                ". For more information please click View Instruction button.",
              is_verified: 0,
            });
          }
        } else {
          this.setState({
            errorMessage: "There is some error while verifying the domain.",
            tableLoader: false,
            disabled: false,
            is_verified: 0,
          });
        }
        this.setState({
          dnsList: servicesResponce.data.dnsList,
          tableLoader: false,
          disabled: false,
          is_verified: 0,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
          is_verified: 0,
        });
      }
    } else {
      this.setState({
        errorMessage: "Domain name cannot be empty.",
        tableLoader: false,
        disabled: false,
        is_verified: 0,
      });
    }
  };

  formAgentUpdate = async () => {
    let {
      title,
      name,
      agent_email,
      agent_password,
      c_password,
      agent_phone,
      agent_company,
    } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
    });
    let is_validated = true;
    if (this.state.name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Agent Name is required.",
      });
      is_validated = false;
    } else if (this.state.title === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Title is required.",
      });
      is_validated = false;
    } else if (this.state.agent_company === "") {
      this.setState({
        errorCompName: "error",
        errorName: "error",
        errorMessage: "Company name is required.",
      });
      is_validated = false;
    } else if (this.state.agent_phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (ValidatePhoneNumber(this.state.agent_phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(this.state.agent_email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.agent_password !== "") {
      if (this.state.agent_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (this.state.c_password === "") {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.c_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.agent_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      let apiResponce = await addNewAgent(
        auth.getAccount(),
        auth.getToken(),
        title,
        name,
        agent_email,
        agent_password,
        agent_phone,
        agent_company
      );
      console.log("Add Agent Response: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessage: "agent added successfully.",
          tableLoader: false,
        });
        setTimeout(async function () {
          window.location.replace("/agents");
        }, 3000);
      } else if (
        apiResponce.data.status === 405 &&
        apiResponce.data.message === "email_exists"
      ) {
        this.setState({
          errorMessage:
            "Email already exists. Please try different email address.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessage: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  render() {
    return (
      <div className="nk-content " id="">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/agents")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Agents
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Agent
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div class="card-head">
                    <h6 class="card-title">
                      Register an {auth.getCompName()} agent / channel partner
                      account. Master agents can add sub agents via their back
                      office.
                    </h6>
                  </div>
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <form
                    method="post"
                    encType="multipart/form-data"
                    autoComplete="off"
                  >
                    <div className="row g-4">
                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="name"
                          name="name"
                          type="text"
                          label="Agent Name"
                          inputProps={{ maxLength: 100 }}
                          defaultValue={this.state.name}
                          onChange={this.handleChange}
                          helperText="Enter the agent name."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="title"
                          name="title"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          label="Agent Title"
                          defaultValue={this.state.title}
                          onChange={this.handleChange}
                          helperText="Enter the title here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_company"
                          name="agent_company"
                          type="text"
                          inputProps={{ maxLength: 100 }}
                          label="Company Name"
                          defaultValue={this.state.agent_company}
                          onChange={this.handleChange}
                          helperText="Enter the company name."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_email"
                          name="agent_email"
                          type="email"
                          label="Email Address"
                          defaultValue={this.state.agent_email}
                          onChange={this.handleChange}
                          helperText="Enter your email address here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                      <PhoneInput
                        required
                        defaultCountry={'us'}
                        inputProps={{
                          name: 'agent_phone',
                          id: 'agent_phone',
                          maxLength:20
                        }}
                        value={this.state.agent_phone}
                        onChange={(value) => this.handleChange({ target: { name: 'agent_phone', value } })}
                        helperText="Enter the phone number here."
                        label="Phone Number"
                      />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_password"
                          name="agent_password"
                          type="password"
                          label="Password"
                          defaultValue={this.state.agent_password}
                          inputProps={{ maxLength: 30 }}
                          onChange={this.handleChange}
                          helperText="Enter your password here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="c_password"
                          name="c_password"
                          type="password"
                          label="Confirm Password"
                          defaultValue={this.state.c_password}
                          inputProps={{ maxLength: 30 }}
                          onChange={this.handleChange}
                          helperText="Enter the confirm password here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div style={{ clear: "both" }}></div>
                      <hr />
                      <div className="form-group text-right col-md-12">
                        <button
                          className="btn btn-lg btn-primary"
                          type="button"
                          disabled={this.state.disabled}
                          onClick={this.formAgentUpdate}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  {/*<ul id="progressbar">
                    <li class="active">Agent Details</li>
                    <li>Domain Verification</li>
                  </ul>
                  <br />
                  {(() => {
                    switch (step) {
                      default:
                        return (
                          <h1>User Forms not working. Enable Javascript!</h1>
                        );
                      case 1:
                        return (
                          <Step1
                            nextStep={this.nextStep}
                            handleChange={this.handleChangeStep}
                            values={values}
                            onFileChange={this.onFileChange}
                          />
                        );
                      case 2:
                        return (
                          <Step2
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChangeStep}
                            VerifyConnection={this.VerifyConnection}
                            formAgentUpdate={this.formAgentUpdate}
                            values={values}
                          />
                        );
                    }
                  })()}
                  <StepWizard>
                    <Step1
                      handleChange={this.handleChangeStep}
                      values={values}
                    />
                    <Step2
                      handleChange={this.handleChangeStep}
                      values={values}
                    />
                  </StepWizard>*/}

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addagent);
