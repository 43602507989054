import React, { useState, useEffect } from 'react';
import HelperClass from "./../../config/helperClass";
import Notifications from './Notifications';
import { FormLoader } from "../ContentLoaders/newLoaders/Form";
import { THEME_COLOR } from "./../../config/config";
import {
  getActivityLogs,
  readAllNotifications
} from "./../../config/api_calls";
import $ from "jquery";
const auth = new HelperClass();

export default function Header(props) {

  const [formLoader, setFormLoader] = useState(true);
  const [notifications, setNotifications] = useState([]);
  function timeConvert(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
    const interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    const interval2 = seconds / 2592000;
    if (interval2 > 1) {
      return Math.floor(interval2) + " months ago";
    }
    const interval3 = seconds / 86400;
    if (interval3 > 1) {
      return Math.floor(interval3) + " days ago";
    }
    const interval4 = seconds / 3600;
    if (interval4 > 1) {
      return Math.floor(interval4) + " hours ago";
    }
    const interval5 = seconds / 60;
    if (interval5 > 1) {
      return Math.floor(interval5) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  const getNotifications = async () => {
    try {
      const getActivityLogs_res = await getActivityLogs(auth.getAccount(), auth.getToken());
      let i;
      const notification = getActivityLogs_res.data.data;
      if (getActivityLogs_res.data.status === 200) {
        let notificationArray = [];
        for (i = 0; i < 3; i++) {
          if (notification[i]) {
            const timeAdded = new Date(notification[i].date_added);
            notificationArray.push({
              title: notification[i].log_title,
              time: timeConvert(timeAdded),
              is_read: notification[i].is_read,
            });
          }
        }
        setNotifications(notificationArray);
        setFormLoader(false)
      } else if(getActivityLogs_res.data.message === 'no_notifications'){
        console.log('No notifications yet');
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleNotifications = () => {
    getNotifications();
  };
  useEffect(() => {
    getNotifications();
  },[]);
  
  const markAllAsRead = async () => {
    const res = await readAllNotifications(auth.getAccount(), auth.getToken());
    if (res.data.status === 200) {
      $('.unread_notification').removeClass('unread_notification');
    }
  }

  function classCheck() {
    var menu_items = $(".nk-sidebar");
    if (menu_items.hasClass("nk-sidebar-active")) {
    } else {
      $(".nk-sidebar").addClass("nk-sidebar-active");
    }
  }

  function navigateLink(link) {
    props.mainProps.history.push(link);
  }

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu" onClick={() => classCheck()}></em>
            </a>
          </div>

          <div className="nk-header-brand d-xl-none">
            <a href="/" className="logo-link">
              <img
                className="logo-light logo-img"
                src="https://www.telecomcloud.us/img/logo.png"
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src="https://www.telecomcloud.us/img/logo.png"
                alt="logo-dark"
              />
            </a>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {props.isNdaSigned === true ? (
                <li>
                  {/*<a
                    className="btn"
                    style={{ backgroundColor: THEME_COLOR, color: "white" }}
                  >
                    <em className="icon ni ni-share-fill"></em>
                    <span>ShareLink</span>{" "}
                  </a>*/}
                </li>
              ) : null}

              <li className="dropdown user-dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt"></em>
                    </div>
                    <div className="user-info d-none d-md-block">
                      <div className="user-status">{auth.getName()}</div>
                      <div className="user-name dropdown-indicator">
                        {auth.getCompName()}
                      </div>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-avatar">
                        <span>{auth.getCompName().substring(0, 2)}</span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">{auth.getCompName()}</span>
                        <span className="sub-text">{auth.getEmail()}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <a href="#" onClick={props.modalOpen}>
                          <em className="icon ni ni-user-alt"></em>
                          <span>View Profile</span>
                        </a>
                      </li>
                      {auth.getUserType() !== "Sub" ? (
                        <li>
                          <a style={{ cursor: "pointer" }} href="/sub_users">
                            <em className="icon ni ni-user-list"></em>
                            <span>Sub Users</span>
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li
                        onClick={() => auth.logout()}
                        style={{ cursor: "pointer" }}
                      >
                        <a>
                          <em className="icon ni ni-signout"></em>
                          <span>Sign out</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="dropdown notification-dropdown mr-n1" onClick={handleNotifications}>
                <a
                  href="#"
                  class="dropdown-toggle nk-quick-nav-icon"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div class="icon-status icon-status-info">
                    <em class="icon ni ni-bell"></em>
                  </div>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1"
                  style={{}}
                >
                  <div class="dropdown-head">
                    <span class="sub-title nk-dropdown-title">
                      Notifications
                    </span>
                    <a href="#" onClick={markAllAsRead}>Mark All as Read</a>
                  </div>
                  <div class="dropdown-body">
                    <div class="nk-notification">
                    {formLoader === true ? (
                      FormLoader()
                    ) : (
                      <>
                      {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                          <Notifications key={index} notification={notification} />
                        ))
                      ) : (
                        <div class="nk-notification-item dropdown-inner">
                            <div class="nk-notification-icon">
                                <em class="icon icon-circle bg-warning-dim ni ni-info"></em>
                            </div>
                            <div class="nk-notification-content">
                                <div class="nk-notification-text">
                                You have <span>no notifactions</span> yet
                                </div>
                            </div>
                        </div>
                      )}
                      </>
                      )}
                    </div>
                  </div>
                  <div class="dropdown-foot center">
                    <a href="/notifications">View All</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
