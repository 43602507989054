import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getAgentSignup,
  regenerateAgentLink,
  cancelAgentLink,
  getSmtpSettings,
  addSmtpSettings,
  verifySmtpSettings,
} from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";

const auth = new HelperClass();

class Upgrades extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
    };
  }

  async componentDidMount() {
    const servicesResponce = await getAgentSignup(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        login_url: servicesResponce.data.login_url,
        logo: servicesResponce.data.logo,
        signature_image: servicesResponce.data.signature,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link generated successfully.",
        reg_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("cancel_link: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  get_smtp_settings = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getSmtpSettings(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("get_smtp_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
        reg_button: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        smtp_host: servicesResponce.data.data[0].smtp_host,
        smtp_user: servicesResponce.data.data[0].smtp_user,
        smtp_password: servicesResponce.data.data[0].smtp_password,
        smtp_method: servicesResponce.data.data[0].smtp_method,
        smtp_port: servicesResponce.data.data[0].smtp_port,
        from_email: servicesResponce.data.data[0].from_email,
        is_working: servicesResponce.data.data[0].is_working,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "no_record"
    ) {
      this.setState({
        smtp_host: "",
        smtp_user: "",
        smtp_password: "",
        smtp_method: "tls",
        smtp_port: "",
        from_email: "",
        is_working: "no",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
        reg_button: false,
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      const servicesResponce = await addSmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email
      );
      //console.log("addSmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings added successfully.",
          smtp_reg_button: false,
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  //Test SMTP Settings

  verify_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_test_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      const servicesResponce = await verifySmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email
      );
      //console.log("verifySmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings tested successfully.",
          smtp_test_button: false,
          is_working: "yes",
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-setting"></em> Settings
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p> &nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                <div className="col-xxl-12">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabItem5"
                          >
                            <em className="icon ni ni-setting"></em>
                            <span>General Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItem6"
                            onClick={() => this.get_smtp_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>SMTP Settings</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tabItem5">
                          <div className="row g-gs">
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-user"></em>{" "}
                                      Agent / Channel Partner Signup Link
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        {this.state.errorMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>{this.state.errorMessage}</p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {this.state.successMessage}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="example-alert">
                                          <div className="alert alert-light alert-icon">
                                            <em className="icon ni ni-alert-circle"></em>{" "}
                                            <strong>NOTE: </strong> Anyone with
                                            this link will be able to create a
                                            super agent for this reseller. Be
                                            very careful where and how you send
                                            this! It is also a good idea to make
                                            sure you have any supporting
                                            documents and contracts in place
                                            before you allow the reseller to
                                            have its first super agent.{" "}
                                          </div>
                                        </div>

                                        <div
                                          className="caption-text"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-light">
                                              <em className="icon ni ni-link"></em>{" "}
                                              {this.state.signup_link &&
                                              this.state.signup_link !== "" ? (
                                                <>{this.state.signup_link}</>
                                              ) : (
                                                <>
                                                  Agent signup link is not
                                                  available. Please create a new
                                                  one by clicking on
                                                  "Regenerate" button.
                                                </>
                                              )}
                                            </div>
                                          </div>
                                          {this.state.reg_button === false ? (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              onClick={() =>
                                                this.regenerate_link()
                                              }
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}

                                          {this.state.cancel_button ===
                                          false ? (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              onClick={() => this.cancel_link()}
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}
                                          {this.state.signup_link &&
                                          this.state.signup_link !== "" ? (
                                            <CopyToClipboard
                                              text={this.state.signup_link}
                                              onCopy={() =>
                                                this.setState({ copied: true })
                                              }
                                            >
                                              <button className="btn btn-success">
                                                <em className="icon ni ni-copy"></em>{" "}
                                                Copy to clipboard{" "}
                                                {this.state.copied ? (
                                                  <span
                                                    style={{ color: "white" }}
                                                  >
                                                    <em className="icon ni ni-done"></em>
                                                  </span>
                                                ) : null}
                                              </button>
                                            </CopyToClipboard>
                                          ) : null}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-signin"></em>{" "}
                                      Agent Login URL
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div
                                        className="alert alert-light alert-icon"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        <em className="icon ni ni-link"></em>{" "}
                                        {this.state.login_url &&
                                        this.state.login_url !== "" ? (
                                          <>{this.state.login_url}</>
                                        ) : (
                                          <>
                                            Agent login link is not available.
                                            Please create a new one by clicking
                                            on "Regenerate" button.
                                          </>
                                        )}
                                      </div>
                                      {/*{this.state.reg_button_login === false ? (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.regenerate_link()}
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}*/}
                                      {this.state.login_url &&
                                      this.state.login_url !== "" ? (
                                        <CopyToClipboard
                                          text={this.state.login_url}
                                          onCopy={() =>
                                            this.setState({ copied2: true })
                                          }
                                        >
                                          <button className="btn btn-success">
                                            <em className="icon ni ni-copy"></em>{" "}
                                            Copy to clipboard{" "}
                                            {this.state.copied2 ? (
                                              <span style={{ color: "white" }}>
                                                <em className="icon ni ni-done"></em>
                                              </span>
                                            ) : null}
                                          </button>
                                        </CopyToClipboard>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-camera"></em>{" "}
                                      Upload Logo and Signature
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Logo
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="logo"
                                                logo_image={this.state.logo}
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Signature
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="signature"
                                                logo_image={
                                                  this.state.signature_image
                                                }
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabItem6">
                          <div className="row g-gs">
                            {this.state.SmtpErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.SmtpErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.SmtpSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.SmtpSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.is_working === "no" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-warning">
                                    <div className="alert-text">
                                      <h4>Warning</h4>
                                      <p>
                                        Your SMTP details are not verified.
                                        Please click "Test SMTP Settings" to
                                        verify the details.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Host or ip address of your smtp server (example: smtp.company.com)"
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    SMTP Host{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_host"
                                    name="smtp_host"
                                    type="text"
                                    label="SMTP Host"
                                    value={this.state.smtp_host}
                                    onChange={this.handleChange}
                                    error={this.state.errorHostName}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Very important: What kind of encryption to use on the SMTP connection."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Security Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_method"
                                    name="smtp_method"
                                    select
                                    label="Security Type"
                                    value={this.state.smtp_method}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setstls1" value="tls">
                                      TLS
                                    </option>
                                    <option key="setssl1" value="ssl">
                                      SSL
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Username for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Username{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_user"
                                    name="smtp_user"
                                    type="text"
                                    label="SMTP User"
                                    value={this.state.smtp_user}
                                    onChange={this.handleChange}
                                    error={this.state.errorUser}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Password for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_password"
                                    name="smtp_password"
                                    type="text"
                                    label="SMTP Password"
                                    value={this.state.smtp_password}
                                    error={this.state.errorPassword}
                                    onChange={this.handleChange}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The default port is 25, but some SMTP servers use a custom port (example: 587,465)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Port <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_port"
                                    name="smtp_port"
                                    select
                                    label="Port"
                                    value={this.state.smtp_port}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="port25" value="25">
                                      25
                                    </option>
                                    <option key="port465" value="465">
                                      465
                                    </option>
                                    <option key="port587" value="587">
                                      587
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The sender email address (example: account@example.com)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Email From{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="from_email"
                                    name="from_email"
                                    type="text"
                                    label="Email From"
                                    value={this.state.from_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorFromEmail}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.smtp_test_button === false ? (
                                  <button
                                    type="button"
                                    className={
                                      this.state.is_working === "yes"
                                        ? "btn btn-lg btn-success"
                                        : "btn btn-lg btn-primary"
                                    }
                                    onClick={() => this.verify_smtp_settings()}
                                    style={{ marginRight: "15px" }}
                                    disabled={this.state.disabled}
                                  >
                                    {this.state.is_working === "yes" ? (
                                      <>
                                        <em class="icon ni ni-done"></em>
                                        SMTP Settings Verified
                                      </>
                                    ) : (
                                      "Test SMTP Settings"
                                    )}
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Test
                                    SMTP Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                                {this.state.smtp_reg_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.add_smtp_settings()}
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Upgrades);
