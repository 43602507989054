import react, { Component } from "react";
import Footer from "./footer";
import Header from "./Header";
import ContentLoader from "react-content-loader";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { addSettings, addMsaInfo } from "../../actions";

import LefeSideBar from "./leftSideBar";
import Dashboard from "../../Pages/Reseller/Dashboard";

import Clients from "../../Pages/Reseller/Clients";
import Clientagreements from "../../Pages/Reseller/Clients/agreements";
import AddClientagreements from "../../Pages/Reseller/Clients/addagreement";
import EditClientagreements from "../../Pages/Reseller/Clients/editagreement";
import Subclients from "../../Pages/Reseller/Clients/subclients";
import Subclientrights from "../../Pages/Reseller/Clients/subclientrights";
import Notifications from "../../Pages/Reseller/Notifications";

import PackageAgreements from "../../Pages/Reseller/PackageAgreements/index";
import AddPackageAgreements from "../../Pages/Reseller/PackageAgreements/addagreement";
import EditPackageAgreements from "../../Pages/Reseller/PackageAgreements/editagreement";

import SubUsers from "../../Pages/Reseller/Users";
import AddUsers from "../../Pages/Reseller/Users/adduser";
import UserRoles from "../../Pages/Reseller/Users/user_roles";

import Services from "../../Pages/Reseller/Services";

import Addservice from "../../Pages/Reseller/Services/addservice";
import Editservice from "../../Pages/Reseller/Services/editservice";
import Addratedeck from "../../Pages/Reseller/Services/addratedeck";
import Addbundle from "../../Pages/Reseller/Services/addbundle";

import Analytics from "../../Pages/Reseller/Analytics";
import Reports from "../../Pages/Reseller/Reports";
import EmailNotifications from "../../Pages/Reseller/Email_notifications";
import Agreements from "../../Pages/Reseller/Agreements";
import Pdfdownload from "../../Pages/Reseller/Agreements/pdfdownload";

import Agents from "../../Pages/Reseller/Agents";
import Addagent from "../../Pages/Reseller/Agents/addagent";

import Agentagreements from "../../Pages/Reseller/Agents/agreements";
import AddAgentagreements from "../../Pages/Reseller/Agents/addagreement";
import EditAgentagreements from "../../Pages/Reseller/Agents/editagreement";

import Upgrades from "../../Pages/Reseller/Upgrades";
import BackOffice from "../../Pages/Reseller/BackOffice";
import MSA from "../../Pages/Reseller/MSA";
import Integrations from "../../Pages/Reseller/Integrations";
import Collaterals from "../../Pages/Reseller/Collaterals";
import DashboardLoader from "../ContentLoaders/dashboard_loader";
import ErrorPage from "../Errors/404";

import Cms from "../../Pages/Reseller/Cms";
import Pageslist from "../../Pages/Reseller/Cms/pageslist";
import Addpages from "../../Pages/Reseller/Cms/addpages";
import Editpage from "../../Pages/Reseller/Cms/editpage";
import Menuslist from "../../Pages/Reseller/Cms/menuslist";
import Addmenus from "../../Pages/Reseller/Cms/addmenus";
import Editmenu from "../../Pages/Reseller/Cms/editmenu";
import Addheader from "../../Pages/Reseller/Cms/addheader";
import Headerlist from "../../Pages/Reseller/Cms/headerlist";
import Websettings from "../../Pages/Reseller/Cms/websettings";

import Themes from "../../Pages/Reseller/Themes";
import Editor from "../../Pages/Reseller/Themes/editor";

import Domains from "../../Pages/Reseller/Domains";
import Adddomains from "../../Pages/Reseller/Domains/adddomains";

import {
  getAccountData,
  profileUpdate,
  resellerLeftMenu,
} from "../../config/api_calls";
import {
  LOGO,
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
} from "../../config/config";
import { REPLACECONTENT, ValidateEmail, normalize } from "../../config/utility";
import HelperClass from "./../../config/helperClass";
import Agreement from "./agreement";

import TextField from "../InputFields/TextField";

const auth = new HelperClass();

class Main extends Component {
  constructor(props) {
    super(props);

    if (auth.isLogin() === false) {
      auth.logout();
    }

    this.state = {
      view: this.props.view,
      activePage: "",
      is_msa_signed: false,
      Agreement_Content: "",
      title_agreement: "",
      bgColor: "#f5f6fa",
      vendor_signor_signature_file: "",
      published_date: "",
      defaultLoader: true,
      errorCompName: "",
      comp_name: "",
      errorName: "",
      name: "",
      errorTitle: "",
      title: "",
      errorEmail: "",
      email: "",
      errorPhone: "",
      phone: "",
      errorPass: "",
      password: "",
      confirm_password: "",
      errorAddress: "",
      address: "",
      errorCity: "",
      city: "",
      errorState: "",
      states: "",
      errorZip: "",
      zip: "",
      errorMessage: "",
      disabled: "",
      successMessage: "",
      routingUrl: "",
      leftMenuArr: [],
    };
  }

  async componentDidMount() {
    if (auth.isLogin() === false) {
      auth.logout();
    } else {
      this.setState({
        comp_name: auth.getCompName(),
        name: auth.getName(),
        email: auth.getEmail(),
        phone: auth.getPhone(),
        title: auth.getTitle(),
      });
      this.defaultChecks();
      await this.getLeftMenu();
    }
  }

  getLeftMenu = async () => {
    let user_type = auth.getUserType();
    let acctno = auth.getAccount();

    if (user_type === "Sub") {
      acctno = auth.getSubUserAccount();
    }
    let res = await resellerLeftMenu(acctno, auth.getToken(), auth.getEmail());
    console.log("getLeftMenu: ", res.data);
    if (
      res.data.status === 200 &&
      res.data.message === "success" &&
      res.data.data.length > 0
    ) {
      this.setState({ leftMenuArr: res.data.data });
    }
  };

  formUpdate = async () => {
    let {
      name,
      email,
      comp_name,
      title,
      phone,
      address,
      city,
      states,
      zip,
      password,
      confirm_password,
    } = this.state;

    let is_validated = true;
    if (comp_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Company Name is required.",
      });
      is_validated = false;
    } else if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
      });
      is_validated = false;
    } else if (title === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Title is required.",
      });
      is_validated = false;
    } else if (phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (password !== "") {
      if (password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (confirm_password === "") {
        this.setState({
          errorPass: "error",

          errorEmail: "",
          errorPhone: "",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (confirm_password.length < 8) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (password.trim() != confirm_password.trim()) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        errorTitle: "",
        disabled: true,
      });

      let apiResponce = await profileUpdate(
        auth.getAccount(),
        auth.getToken(),
        name,
        title,
        comp_name,
        phone,
        address,
        city,
        states,
        zip,
        password
      );
      //console.log(apiResponce);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        Cookies.set(
          "comp_nme__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["comp_name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "name__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "phone__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["phone"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "title__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["title"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );

        this.setState({
          successMessage: "Profile updated successfully.",
          disabled: false,
        });

        setTimeout(async function () {
          window.$("#modalFormProfile").modal("hide");
        }, 2000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try later.",
          disabled: false,
        });
        return false;
      } else {
        auth.logout();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  agreementSigned = () => {
    this.setState({ is_msa_signed: true });
  };

  defaultChecks = async () => {
    let apiData = await getAccountData(auth.getAccount(), auth.getToken());
    //console.log("NDA DATA: ", apiData.data.data);
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (apiData.data.status === 404 || apiData.data.data.length === 0) {
      this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.props.addSettings(apiData.data.data[0]["settings"]);
      this.props.addMsaInfo(apiData.data.data[0]["is_msa_signed"]);

      this.setState({
        address: apiData.data.data[0]["address"],
        city: apiData.data.data[0]["city"],
        states: apiData.data.data[0]["state"],
        zip: apiData.data.data[0]["zip"],
        routingUrl: apiData.data.data[0]["url"],
      });
      if (apiData.data.data[0]["is_msa_signed"] === "No") {
        let objReplace = {
          resellerCompName: auth.getCompName(),
          reseller_name: apiData.data.data[0]["name"],
          reseller_comp_name: apiData.data.data[0]["comp_name"],
          reseller_email: apiData.data.data[0]["email"],
          reseller_address: apiData.data.data[0]["address"]
            ? apiData.data.data[0]["address"]
            : "N/A",
          reseller_city: apiData.data.data[0]["city"]
            ? apiData.data.data[0]["city"]
            : "N/A",
          reseller_state: apiData.data.data[0]["state"]
            ? apiData.data.data[0]["state"]
            : "N/A",
          reseller_zip: apiData.data.data[0]["zip"]
            ? apiData.data.data[0]["zip"]
            : "N/A",
          reseller_phone_no: apiData.data.data[0]["phone"],
          vendor_signor_name: apiData.data.agreement[0].vendor_signor_name,
          vendor_signor_comp_name:
            apiData.data.agreement[0].vendor_signor_comp_name,
          vendor_signor_title: apiData.data.agreement[0].vendor_signor_title,
          vendor_signor_address:
            apiData.data.agreement[0].vendor_signor_address,
          vendor_signor_email: apiData.data.agreement[0].vendor_signor_email,
          vendor_signor_phone_no:
            apiData.data.agreement[0].vendor_signor_phone_no,
          vendor_signor_fax: apiData.data.agreement[0].vendor_signor_fax,
          vendor_signor_city: apiData.data.agreement[0].vendor_signor_city,
          vendor_signor_state: apiData.data.agreement[0].vendor_signor_state,
          vendor_signor_zip: apiData.data.agreement[0].vendor_signor_zip,
          vendor_signor_comp_title:
            apiData.data.agreement[0].vendor_signor_comp_title,
          def_did_charges: apiData.data.rates[0].did[0].mrc
            ? apiData.data.rates[0].did[0].mrc
            : "--",
          def_tfn_charges: apiData.data.rates[0].tfn[0].mrc
            ? apiData.data.rates[0].tfn[0].mrc
            : "--",
          def_sms_charges: apiData.data.rates[0].sms[0].mrc
            ? apiData.data.rates[0].sms[0].mrc
            : "--",
          def_term_charges: apiData.data.rates[0].term[0].mrc
            ? apiData.data.rates[0].term[0].mrc
            : "--",
          def_live_agent_charges: apiData.data.rates[0].live_agent[0].mrc
            ? apiData.data.rates[0].live_agent[0].mrc
            : "--",
          def_ivr_charges: apiData.data.rates[0].ivr[0].mrc
            ? apiData.data.rates[0].ivr[0].mrc
            : "--",
          def_did_rates: apiData.data.rates[0].did[0].rate
            ? apiData.data.rates[0].did[0].rate
            : "--",
          def_tfn_rates: apiData.data.rates[0].tfn[0].rate
            ? apiData.data.rates[0].tfn[0].rate
            : "--",
          def_sms_rates: apiData.data.rates[0].sms[0].rate
            ? apiData.data.rates[0].sms[0].rate
            : "--",
          def_term_rates: apiData.data.rates[0].term[0].rate
            ? apiData.data.rates[0].term[0].rate
            : "--",
          def_la_rates: apiData.data.rates[0].live_agent[0].rate
            ? apiData.data.rates[0].live_agent[0].rate
            : "--",
          def_ivr_rates: apiData.data.rates[0].ivr[0].rate
            ? apiData.data.rates[0].ivr[0].rate
            : "--",
          def_did_nrc: apiData.data.rates[0].did[0].nrc
            ? apiData.data.rates[0].did[0].nrc
            : "--",
          def_tfn_nrc: apiData.data.rates[0].tfn[0].nrc
            ? apiData.data.rates[0].tfn[0].nrc
            : "--",
        };

        let newContent = REPLACECONTENT(
          apiData.data.agreement[0]["agreement_content"],
          objReplace,
          ""
        );

        newContent = newContent.replace(
          "VENDOR SIGNATURE DATE SHOWN HERE",
          apiData.data.agreement[0]["published_date"]
        );

        this.setState({
          is_msa_signed: false,
          Agreement_Content: newContent.replace(
            /\[\[IMG_VENDOR_SIGN\]\]/g,
            VENDOR_SIGNATURE
          ),
          title_agreement: apiData.data.agreement[0]["agreement_title"],
          bgColor: "white",
          vendor_signor_signature_file:
            apiData.data.agreement[0]["vendor_signor_signature_file"],
          published_date: apiData.data.agreement[0]["published_date"],
          defaultLoader: false,
        });
      } else if (apiData.data.data[0]["is_msa_signed"] === "Yes") {
        this.setState({
          is_msa_signed: true,
          defaultLoader: false,
        });
      }
    } else if (
      (apiData.data.status === 200 &&
        apiData.data.data[0]["isVerified"] === "No") ||
      apiData.data.data[0]["account_status"] !== "active"
    ) {
      auth.logout();
    } else {
      this.props.history.push("/error");
    }
  };

  pageView = () => {
    //console.log("PAGE PROP " + this.state.view);
    if (this.state.view === "dashboard") {
      return <Dashboard props={this.props} />;
    } else if (this.state.view === "clients") {
      return <Clients props={this.props} />;
    } else if (this.state.view === "subclients") {
      return <Subclients props={this.props} />;
    } else if (this.state.view === "subclientrights") {
      return <Subclientrights props={this.props} />;
    } else if (this.state.view === "sub_users") {
      return <SubUsers props={this.props} />;
    } else if (this.state.view === "user_roles") {
      return <UserRoles props={this.props} />;
    } else if (this.state.view === "adduser") {
      return <AddUsers props={this.props} />;
    } else if (this.state.view === "Clientagreements") {
      return <Clientagreements props={this.props} />;
    } else if (this.state.view === "AddClientagreements") {
      return <AddClientagreements props={this.props} />;
    } else if (this.state.view === "EditClientagreements") {
      return <EditClientagreements props={this.props} />;
    } else if (this.state.view === "services") {
      return <Services props={this.props} />;
    } else if (this.state.view === "cms") {
      return <Cms props={this.props} />;
    } else if (this.state.view === "Pageslist") {
      return <Pageslist props={this.props} />;
    } else if (this.state.view === "Addpages") {
      return <Addpages props={this.props} />;
    } else if (this.state.view === "Editpage") {
      return <Editpage props={this.props} />;
    } else if (this.state.view === "Pdfdownload") {
      return <Pdfdownload props={this.props} />;
    } else if (this.state.view === "Editmenu") {
      return <Editmenu props={this.props} />;
    } else if (this.state.view === "Menuslist") {
      return <Menuslist props={this.props} />;
    } else if (this.state.view === "Addmenus") {
      return <Addmenus props={this.props} />;
    } else if (this.state.view === "Addheader") {
      return <Addheader props={this.props} />;
    } else if (this.state.view === "Headerlist") {
      return <Headerlist props={this.props} />;
    } else if (this.state.view === "Websettings") {
      return <Websettings props={this.props} />;
    } else if (this.state.view === "Addservice") {
      return <Addservice props={this.props} />;
    } else if (this.state.view === "Editservice") {
      return <Editservice props={this.props} />;
    } else if (this.state.view === "Addratedeck") {
      return <Addratedeck props={this.props} />;
    } else if (this.state.view === "Addbundle") {
      return <Addbundle props={this.props} />;
    } else if (this.state.view === "Adddomains") {
      return <Adddomains props={this.props} />;
    } else if (this.state.view === "analytics") {
      return <Analytics props={this.props} />;
    } else if (this.state.view === "reports") {
      return <Reports props={this.props} />;
    } else if (this.state.view === "agreements") {
      return <Agreements props={this.props} />;
    } else if (this.state.view === "package_agreements") {
      return <PackageAgreements props={this.props} />;
    } else if (this.state.view === "add_package_agreement") {
      return <AddPackageAgreements props={this.props} />;
    } else if (this.state.view === "edit_package_agreement") {
      return <EditPackageAgreements props={this.props} />;
    } else if (this.state.view === "themes") {
      return <Themes props={this.props} />;
    } else if (this.state.view === "domains") {
      return <Domains props={this.props} />;
    } else if (this.state.view === "Editor") {
      return <Editor props={this.props} />;
    } else if (this.state.view === "agents") {
      return <Agents props={this.props} />;
    } else if (this.state.view === "Addagent") {
      return <Addagent props={this.props} />;
    } else if (this.state.view === "Agentagreements") {
      return <Agentagreements props={this.props} />;
    } else if (this.state.view === "AddAgentagreements") {
      return <AddAgentagreements props={this.props} />;
    } else if (this.state.view === "EditAgentagreements") {
      return <EditAgentagreements props={this.props} />;
    } else if (this.state.view === "upgrades") {
      return <Upgrades props={this.props} />;
    } else if (this.state.view === "Integrations") {
      return <Integrations props={this.props} />;
    } else if (this.state.view === "Collaterals") {
      return <Collaterals props={this.props} />;
    } else if (this.state.view === "back office") {
      return <BackOffice props={this.props} />;
    }else if (this.state.view === "notifications") {
      return <Notifications props={this.props} />;
    }
    if (this.state.view === "msa_signing") {
      return <MSA />;
    } else {
      return <ErrorPage />;
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  pageChange = (data) => {
    this.props.history.push("/" + data);
  };

  profileView = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    window.$("#modalFormProfile").modal("show");
  };

  render() {
    return (
      <div className="nk-body bg-lighter npc-general has-sidebar ">
        <div className="nk-app-root">
          <div className="nk-main">
            {auth.isLogin() !== false ? (
              <div>
                <LefeSideBar
                  mainProps={this.props}
                  routingUrl={this.state.routingUrl}
                  menuArr={this.state.leftMenuArr}
                  {...this.props}
                />
              </div>
            ) : null}

            <div className="nk-wrap ">
              <Header
                isNdaSigned={this.state.is_msa_signed}
                modalOpen={() => {
                  this.profileView();
                }}
              />

              {this.state.is_msa_signed === true ? (
                this.pageView()
              ) : (
                <Agreement
                  content={this.state.Agreement_Content}
                  title={this.state.title_agreement}
                  backgroundColor={this.state.bgColor}
                  vendor_signor_signature_file={
                    this.state.vendor_signor_signature_file
                  }
                  published_date={this.state.published_date}
                  mainHistory={this.props}
                  agreementSigned={() => this.agreementSigned()}
                />
              )}
              {/* MODAL EDIT PROFILE START */}
              <div className="modal fade" tabIndex="-1" id="modalFormProfile">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Profile</h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />

                      <div className="row g-4">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Company Name"
                              error={this.state.errorCompName}
                              id="comp_name"
                              name="comp_name"
                              value={this.state.comp_name}
                              placeholder="Company Name"
                              change={this.handleChange}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Full Name"
                              error={this.state.errorName}
                              id="name"
                              name="name"
                              value={this.state.name}
                              placeholder="Full Name"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Title"
                              error={this.state.errorTitle}
                              id="title"
                              name="title"
                              value={this.state.title}
                              placeholder="Title"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Email"
                              type="text"
                              error={this.state.errorEmail}
                              id="email"
                              name="email"
                              value={this.state.email}
                              placeholder="Enter your email address"
                              change={this.handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Phone"
                              type="number"
                              error={this.state.errorPhone}
                              id="phone"
                              name="phone"
                              value={this.state.phone}
                              placeholder="Enter your phone"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Address"
                              type="text"
                              error={this.state.errorAddress}
                              id="address"
                              name="address"
                              value={this.state.address}
                              placeholder="Enter your address"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="City"
                              type="text"
                              error={this.state.errorCity}
                              id="city"
                              name="city"
                              value={this.state.city}
                              placeholder="Enter your city"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="State"
                              type="text"
                              error={this.state.errorState}
                              id="states"
                              name="states"
                              value={this.state.states}
                              placeholder="Enter your state"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Zip Code"
                              type="text"
                              error={this.state.errorZip}
                              id="zip"
                              name="zip"
                              value={this.state.zip}
                              placeholder="Enter your zip code"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Password"
                              type="password"
                              error={this.state.errorPass}
                              id="password"
                              name="password"
                              value={this.state.password}
                              placeholder="Enter your password"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Confirm Password"
                              type="password"
                              error={this.state.errorPass}
                              id="confirm_password"
                              name="confirm_password"
                              value={this.state.confirm_password}
                              placeholder="Enter confirm password"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">&nbsp;</div>
                        <div style={{ clear: "both" }}></div>
                        {/* <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorPass
                            }
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter your password"
                          />
                        </div>
                      </div> */}
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-lg"
                            onClick={() => {
                              this.formUpdate();
                            }}
                            style={{
                              backgroundColor: THEME_COLOR,
                              color: THEME_TEXT_COLOR,
                              float: "right",
                            }}
                            disabled={this.state.disabled}
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END MODAL EDIT PROFILE */}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
    addMsaInfo: (payload) => dispatch(addMsaInfo(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
